<template>
    <div class="gst-help-top d-flex u-width-100 flex-column">
        <div class="gst-help-top__content__container">
            <div v-if="isPurchaseFlowEventTicketTypeBoth" class="gst-help-top__content">
                <div class="gst-help-top__content__section d-flex flex-column flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_TM" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Purchase Tickets Directly on Ticketmaster and Earn Rewards
                        </h5>
                        <p>
                            Now you can buy tickets for events outside of the United States directly on Ticketmaster to earn rewards. <b> To earn rewards, members must first login, visit this site to select a non-US event, and follow the link to Ticketmaster before every ticket purchase. </b> The rewards you earn for this purchase will be deposited into your account soon after the purchase is completed.
                        </p>
                    </div>
                </div>
            </div>
            <div v-else-if="isPurchaseFlowEventTicketTypeProvider" class="gst-help-top__content">
                <div class="gst-help-top__content__section d-flex flex-column flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_TM" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Purchase Tickets Directly on Ticketmaster and Earn Rewards
                        </h5>
                        <p>
                            To earn rewards, members must first login, visit this site to select an event, and follow the link to Ticketmaster before every ticket purchase. The rewards you earn for this purchase will be deposited into your account soon after the purchase is completed.
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="isPurchaseFlowEventTicketTypeProvider" class="gst-help-top__content">
                <div class="gst-help-top__content__section d-flex flex-column flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_login" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Log In to Your Rewards Account
                        </h5>
                        <p>
                            Members can earn rewards for events they love such as concerts, sporting events, family shows and more. You get access to all of Ticketmaster tickets at the exact same prices. Members will get rewarded by initiating the purchase process here and completing it on Ticketmaster!
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="isPurchaseFlowEventTicketTypeProvider" class="gst-help-top__content">
                <div class="gst-help-top__content__section d-flex flex-column flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_search_for_an_event" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Search for an Event and Follow the Link to Ticketmaster
                        </h5>
                        <p>
                            Simply search for events on our website, and allow us to redirect you to Ticketmaster to complete your purchase. Whether you are looking for standard event tickets or verified resale tickets, they are all eligible for rewards.
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="isPurchaseFlowEventTicketTypeProvider" class="gst-help-top__content d-flex align-start">
                <div class="gst-help-top__content__section d-flex flex-column u-width-100 flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_get_rewarded" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Purchase Your Tickets and Get Rewarded
                        </h5>
                        <p>
                            Complete your purchase on Ticketmaster and recieve your digital ticket. Within 1-2 days, you’ll recieve the rewards you’ve earned.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default ( {
        name: 'HelpEnTop',
        components: {
            BaseIcon
        },
        computed: {
            ...mapGetters ( 'appTenant', {
                'isPurchaseFlowEventTicketTypeProvider': 'isPurchaseFlowEventTicketTypeProvider',
                'isPurchaseFlowEventTicketTypeDirect': 'isPurchaseFlowEventTicketTypeDirect',
                'isPurchaseFlowEventTicketTypeBoth':'isPurchaseFlowEventTicketTypeBoth',
                'purchaseFlowEventTicketType': 'purchaseFlowEventTicketType'
            } ),
        }
    } );
</script>


<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-help-top {
    padding: theme-spacing( 8, 0 );
    margin: auto;
    margin-top: theme-spacing( 3 );
    background-color: white;

    .gst-help-top__content__container {
        margin: auto;
    }

    .gst-help-top__content {
        width: 70%;
        flex: 1 50%;

        .icon {
            margin-right: 22px;
        }
    }

    .text {
        overflow-wrap: break-word;

        h5 {
            font-weight: font-weight( 'medium' );
        }

        p {
            font-weight: font-weight( 'regular' );
        }
    }
}

@include mobile-only {
    .gst-help-top {
        padding-left: theme-spacing( 4 );

        .gst-help-top__content__container {
            width: 100%;
            margin: auto;
        }

        .gst-help-top__content {
            width: 100%;

            .icon {
                margin-right: 22px;
            }
        }
    }
}
</style>
