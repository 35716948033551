<template>
    <div class="gst-help u-width-100 d-flex flex-column align-center">
        <div v-if="!isPurchaseFlowEventTicketTypeDirect" class="u-width-100">
            <HelpFrTop />
        </div>
        <div class="gst-help-bottom d-flex flex-wrap flex-row" :class="returnClassBasedOnTransactionType">
            <div v-if="!isPurchaseFlowEventTicketTypeProvider" class="gst-help-bottom__section mr-md-6">
                <div class="gst-help-bottom__section__icon d-flex align-center">
                    <BaseIcon symbol-id="illustrations--best_deals" />
                </div>
                <div class="gst-help-bottom__section__text d-flex flex-column">
                    <h5>
                        Laissez un billet vous mener au suivant
                    </h5>
                    <p>
                        Members can earn rewards for events they love such as concerts, sporting events, family shows and more. To earn rewards, just search and purchase your event tickets from this website and you will earn on every Ticketmaster purchase.
                    </p>
                </div>
            </div>
            <div class="gst-help-bottom__section">
                <div class="gst-help-bottom__section__icon d-flex align-center">
                    <BaseIcon symbol-id="illustrations--best_prices" />
                </div>
                <div class="gst-help-bottom__section-text d-flex flex-column">
                    <h5>
                        Même prix des billets, avec le bonus de gagner des récompenses
                    </h5>
                    <p>
                        Vous avez accès à tous les mêmes billets Ticketmaster disponibles sur leur site Web habituel aux mêmes prix, mais les membres seront récompensés simplement en achetant ici !
                    </p>
                </div>
            </div>
            <div class="gst-help-bottom__section">
                <div class="gst-help-bottom__section__icon d-flex align-center">
                    <BaseIcon symbol-id="illustrations--always_on_time" />
                </div>
                <div class="gst-help-bottom__section__text d-flex flex-column">
                    <h5>
                        Obtenez vos billets immédiatement
                    </h5>
                    <p>
                        Vos billets seront mis à votre disposition par voie électronique pour vous assurer de ne pas manquer votre événement. Votre compte de récompenses sera crédité dans les 1 à 2 jours suivant votre achat.
                    </p>
                </div>
            </div>
            <div class="gst-help-bottom__section">
                <div class="gst-help-bottom__section__icon d-flex align-center">
                    <BaseIcon symbol-id="illustrations--best_ticket" />
                </div>
                <div class="gst-help-bottom__section__text d-flex flex-column">
                    <h5>
                        Meilleure sélection de billets
                    </h5>
                    <p>
                        Que vous recherchiez des billets d'événement standard ou des billets de revente vérifiés, vous les trouverez ici et serez récompensé à chaque achat.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import HelpFrTop from '@core/static/Help/Help_fr-top.vue';

    export default {
        name: 'HelpFr',
        components: {
            BaseIcon,
            HelpFrTop
        },
        computed: {
            ...mapGetters ( 'appTenant', {
                'isPurchaseFlowEventTicketTypeProvider': 'isPurchaseFlowEventTicketTypeProvider',
                'isPurchaseFlowEventTicketTypeDirect': 'isPurchaseFlowEventTicketTypeDirect',
                'isPurchaseFlowEventTicketTypeBoth':'isPurchaseFlowEventTicketTypeBoth',
                'purchaseFlowEventTicketType': 'purchaseFlowEventTicketType'
            } ),
            returnClassBasedOnTransactionType() {
                if ( this.isPurchaseFlowEventTicketTypeProvider ) {
                    return 'gst-help-bottom';
                }
                else if ( this.isPurchaseFlowEventTicketTypeBoth ) {
                    return 'small-padding';
                }
                else
                    return 'big-padding';
            }
        }
    };
</script>