<template>
    <div class="gst-help-top d-flex u-width-100 flex-column">
        <div class="gst-help-top__content__container">
            <div v-if="isPurchaseFlowEventTicketTypeBoth" class="gst-help-top__content">
                <div class="gst-help-top__content__section d-flex flex-column flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_TM" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Achetez des billets directement sur Ticketmaster et gagnez des récompenses
                        </h5>
                        <p>
                            Vous pouvez désormais acheter des billets pour des événements en dehors des États-Unis directement sur Ticketmaster pour gagner des récompenses. <b> Pour gagner des récompenses, les membres doivent d'abord se connecter, visiter ce site pour sélectionner un événement non américain et suivre le lien vers Ticketmaster avant chaque achat de billet. </b> Les récompenses que vous gagnez pour cet achat seront déposées sur votre compte peu après la fin de l'achat.
                        </p>
                    </div>
                </div>
            </div>
            <div v-else-if="isPurchaseFlowEventTicketTypeProvider" class="gst-help-top__content">
                <div class="gst-help-top__content__section d-flex flex-column flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_TM" />
                    </div>
                    <div class="gst-help-top__content-section-text d-flex flex-column">
                        <h5>
                            Achetez des billets directement sur Ticketmaster et gagnez des récompenses
                        </h5>
                        <p>
                            Pour gagner des récompenses, les membres doivent d'abord se connecter, visiter ce site pour sélectionner un événement et suivre le lien vers Ticketmaster avant chaque achat de billet. Les récompenses que vous gagnez pour cet achat seront déposées sur votre compte peu après la fin de l'achat.
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="isPurchaseFlowEventTicketTypeProvider" class="gst-help-top__content">
                <div class="gst-help-top__content__section d-flex flex-column flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_login" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Connectez-vous à votre compte de récompenses
                        </h5>
                        <p>
                            Les membres peuvent gagner des récompenses pour les événements qu'ils aiment tels que des concerts, des événements sportifs, des spectacles familiaux et plus encore. Vous avez accès à tous les billets Ticketmaster aux mêmes prix. Les membres seront récompensés en lançant le processus d'achat ici et en le complétant sur Ticketmaster !
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="isPurchaseFlowEventTicketTypeProvider" class="gst-help-top__content">
                <div class="gst-help-top__content__section d-flex flex-column flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_search_for_an_event" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Recherchez un événement et suivez le lien vers Ticketmaster
                        </h5>
                        <p>
                            Recherchez simplement des événements sur notre site Web et permettez-nous de vous rediriger vers Ticketmaster pour finaliser votre achat. Que vous recherchiez des billets d'événement standard ou des billets de revente vérifiés, ils sont tous éligibles pour des récompenses.
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="isPurchaseFlowEventTicketTypeProvider" class="gst-help-top__content d-flex align-start">
                <div class="gst-help-top__content__section d-flex flex-column u-width-100 flex-md-row">
                    <div class="icon d-flex ">
                        <BaseIcon symbol-id="illustrations--how_it_works_get_rewarded" />
                    </div>
                    <div class="text d-flex flex-column">
                        <h5>
                            Achetez vos billets et soyez récompensé
                        </h5>
                        <p>
                            Complétez votre achat sur Ticketmaster et recevez votre billet numérique. Dans un délai de 1 à 2 jours, vous recevrez les récompenses que vous avez gagnées.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default ( {
        name: 'HelpFrTop',
        components: {
            BaseIcon
        },
        computed: {
            ...mapGetters ( 'appTenant', {
                'isPurchaseFlowEventTicketTypeProvider': 'isPurchaseFlowEventTicketTypeProvider',
                'isPurchaseFlowEventTicketTypeDirect': 'isPurchaseFlowEventTicketTypeDirect',
                'isPurchaseFlowEventTicketTypeBoth':'isPurchaseFlowEventTicketTypeBoth',
                'purchaseFlowEventTicketType': 'purchaseFlowEventTicketType'
            } ),
        }
    } );
</script>