<template>
    <BaseBanner class="gst-banner--detail">
        <template v-slot:gst-banner-content>
            <BaseImage
                v-bind="imageProps"
                class="gst-banner__image pb-5"
                :class="imgClasses"
                @error="onErrorDo">
                <template v-slot:content>
                    <ImagePublicAssets
                        v-if="isError"
                        :path-key="src"
                        v-bind="{
                            gradient: imageProps.gradient,
                            position: imageProps.position,
                            validateSrcContentSize: imageProps.validateSrcContentSize
                        }"
                        class="gst-banner__image-fallback" />
                    <v-flex d-flex flex-column justify-space-between class="content-max-width u-height-100">
                        <div class="text-right">
                            <slot name="favorite"></slot>
                        </div>
                        <div>
                            <h1 class="d-inline-block u-align-ve">
                                {{ title }}
                            </h1>
                        </div>
                    </v-flex>
                </template>
            </BaseImage>
        </template>
    </BaseBanner>
</template>
<script>
    import BaseImage from '@core/shared/components/misc/BaseImage.vue';
    import BaseBanner from '@core/shared/components/misc/BaseBanner.vue';
    import ImagePublicAssets from '@core/shared/components/images/ImagePublicAssets.vue';

    export default {
        name: 'DetailBanner',
        components: {
            BaseBanner,
            BaseImage,
            ImagePublicAssets
        },
        props: {
            fallbackSrc: {
                type: String,
                default: null
            },
            title: {
                type: String,
                default: '',
                required: true
            },
            src: {
                type: String,
                default: null
            },
            imgClasses: {
                type: String,
                default: ''
            },
            imgHeight: {
                type: Number,
                default: 180
            },
            gradient: {
                type: String,
                default: 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)',
            },
            displayGradient: {
                type: Boolean,
                default: true
            },
        },
        data: () => ( {
            isError: false
        } ),
        computed: {
            imageProps() {
                return {
                    src: this.src,
                    fallbackSrc: this.fallbackSrc,
                    height: this.imgHeight,
                    gradient:  this.displayGradient ? this.gradient : '',
                    position: 'top center',
                    validateSrcContentSize: true
                };
            }
        },
        methods: {
            onErrorDo() {
                this.isError = true;
            }
        }
    };
</script>
<style lang="scss" scoped>
@import "@scssVariables";
@import "@scssMixins";

.gst-banner--detail {
    .gst-banner__image {
        height: 200px;

        @include mobile-only {
            padding: theme-spacing( 0, 4 );
        }
    }

    .gst-banner__image-fallback {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
}
</style>
