const imageConstants =  {
    BANNERS: {
        SEARCH:         '@tenants/_main/assets/banners/search_page_banner.png',
        STATIC:         '@tenants/_main/assets/banners/static_page_banner.png',
        VENUE:          '@tenants/_main/assets/banners/search_page_banner.png',
        USER_FAVORITES: '@tenants/_main/assets/banners/user_favorites_page_banner.png',
    },
    FALLBACKS: {
        NO_DATA:                    '@tenants/_main/assets/fallbackImages/no_events_available.png',
        NO_TICKETS:                 '@tenants/_main/assets/fallbackImages/no_tickets_available.png',
        NOT_FOUND:                  '@tenants/_main/assets/fallbackImages/404.png',
        SPORTS:                     '@tenants/_main/assets/fallbackImages/sports.png',
        ARTS:                       '@tenants/_main/assets/fallbackImages/arts.png',
        CONCERTS:                   '@tenants/_main/assets/fallbackImages/concerts.png',
        FAMILY:                     '@tenants/_main/assets/fallbackImages/family.png',
        OTHER_EVENTS:               '@tenants/_main/assets/fallbackImages/other_events.png',
        EVENT_TICKETS_MAINTENANCE:  '@tenants/_main/assets/fallbackImages/event_tickets_maintenance.png',
    },
    BONUS_POINTS: {
        DESKTOP: {
            HOME:                  '@tenants/_main/assets/banners/desktop_landing_page_banner.png',
            CHECKOUT_SUMMARY:      '@tenants/_main/assets/banners/desktop_summary_banner.png',
            CHECKOUT_CONFIRMATION: '@tenants/_main/assets/banners/desktop_success_banner.png'
        },
        MOBILE: {
            HOME:                  '@tenants/_main/assets/banners/mobile_landing_page_banner.png',
            CHECKOUT_SUMMARY:      '@tenants/_main/assets/banners/mobile_summary_banner.png',
            CHECKOUT_CONFIRMATION: '@tenants/_main/assets/banners/mobile_success_banner.png'
        }
    }
};

export default imageConstants;
