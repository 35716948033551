<template>
    <BaseImage class="gst-image-public-assets" v-bind="{ ...$attrs, ...imageProps }" @error="onErrorDo" @load="onLoadDo">
        <template v-if="isError && !isLoading" v-slot:fallback>
            <v-img class="gst-image-public-assets__fallback" v-bind="$attrs">
                <slot></slot>
            </v-img>
        </template>
        <template v-slot:content>
            <slot></slot>
        </template>
    </BaseImage>
</template>

<script>
    import { mapGetters } from 'vuex';
    import getValueAtObjectPath from 'lodash/get';
    import imageConstants from '@core/utils/constants/imagePaths';
    import BaseImage from '@core/shared/components/misc/BaseImage.vue';
    import LogService from '@core/services/LogService';

    export default {
        name: 'ImagePublicAssets',
        components: {
            BaseImage
        },
        props: {
            pathKey: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                isError: false,
                imageSrc: null,
                isLoading: false
            };
        },
        computed: {
            ...mapGetters ( 'appTenant', {
                'tenantImagePaths': 'imagePaths'
            } ),
            imageProps() {
                return {
                    src: this.imageSrc,
                    validateSrcContentSize: true
                };
            }
        },
        watch: {
            pathKey: {
                handler: async function( value ) {
                    if ( value ) {
                        await this.loadImage();
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        methods: {
            onErrorDo() {
                this.isError = true;
                if ( this.imageSrc ) {
                    LogService.error( 'Error loading image: "' + this.imageSrc + '"' );
                    this.loadFallbackImage( );
                }
            },
            onLoadDo() {
                this.isError = false;
            },
            async loadFallbackImage( ) {
                const path = getValueAtObjectPath( imageConstants, this.pathKey );
                const pathSuffix = path.split( '/' ).slice( 3 ).join( '/' );

                try {
                    const response = await import( `@tenants/_main/assets/${pathSuffix}` );
                    this.imageSrc = response.default;
                } catch ( error ) {
                    LogService.error( 'Error loading fallback image: "' + path + '"' );
                }
            },
            async loadAssetsImage( ) {
                const path = getValueAtObjectPath( this.tenantImagePaths, this.pathKey );

                try {
                    const response = await import( `@tenant/assets${path}` );
                    this.imageSrc = response.default;
                } catch ( error ) {
                    this.imageSrc = path;
                }
            },
            async loadImage() {
                const path = getValueAtObjectPath( this.tenantImagePaths, this.pathKey );

                if ( path ) {
                    const isFallback = path.includes( '@' );
                    this.isLoading = true;
                    if ( isFallback ) {
                        await this.loadFallbackImage( );
                    } else {
                        await this.loadAssetsImage( );
                    }
                    this.isLoading = false;
                } else {
                    this.imageSrc = null;
                }
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.v-application .gst-image-public-assets {
    color: inherit !important;

    .gst-image-public-assets__fallback {
        height: 100%;
        width: 100%;
        background: url( '~@core/shared/assets/icons/image.png' ) no-repeat center center, rgba( theme-color-hex( 'quaternary' ), 0.2 );
    }
}

</style>