import { mapState } from 'vuex';

let mixin = {
    computed: {
        ...mapState( {
            language:  state => state.appState.language
        } ),
        bannerImage() {
            return 'BANNERS.STATIC';
        }
    }
};

export default mixin;
