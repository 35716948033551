<template>
    <div class="gst-help u-width-100 d-flex flex-column align-center">
        <div class="u-width-100">
            <HelpEnTop class="content-max-width" />
        </div>
        <div class="gst-help-bottom u-width-100" :class="returnClassBasedOnTransactionType">
            <div class="content-max-width d-flex flex-wrap flex-row">
                <div v-if="!isPurchaseFlowEventTicketTypeProvider" class="gst-help-bottom__section mr-md-6">
                    <div class="gst-help-bottom__section__icon d-flex align-center">
                        <BaseIcon symbol-id="illustrations--best_deals" />
                    </div>
                    <div class="gst-help-bottom__section__text d-flex flex-column">
                        <h5>
                            Let One Ticket Lead You to the Next
                        </h5>
                        <p>
                            Members can earn rewards for events they love such as concerts, sporting events, family shows and more. To earn rewards, just search and purchase your event tickets from this website and you will earn on every Ticketmaster purchase.
                        </p>
                    </div>
                </div>
                <div class="gst-help-bottom__section">
                    <div class="gst-help-bottom__section__icon d-flex align-center">
                        <BaseIcon symbol-id="illustrations--best_prices" />
                    </div>
                    <div class="gst-help-bottom__section__text d-flex flex-column">
                        <h5>
                            Same Ticket Prices, with the Bonus of Earning Rewards
                        </h5>
                        <p>
                            You get access to all of the same Ticketmaster tickets available from their regular website at the exact same prices, but members will get rewarded just by purchasing here!
                        </p>
                    </div>
                </div>
                <div class="gst-help-bottom__section">
                    <div class="gst-help-bottom__section__icon d-flex align-center">
                        <BaseIcon symbol-id="illustrations--always_on_time" />
                    </div>
                    <div class="gst-help-bottom__section__text d-flex flex-column">
                        <h5>
                            Get Your Tickets Immediately
                        </h5>
                        <p>
                            Your tickets will be made available to you electronically to ensure you do not miss your event. Your rewards account will be credited within 1-2 days of your purchase.
                        </p>
                    </div>
                </div>

                <div class="gst-help-bottom__section">
                    <div class="gst-help-bottom__section__icon d-flex align-center">
                        <BaseIcon symbol-id="illustrations--best_ticket" />
                    </div>
                    <div class="gst-help-bottom__section__text d-flex flex-column">
                        <h5>
                            Best Ticket Selection
                        </h5>
                        <p>
                            Whether you are looking for standard event tickets or verified resale tickets, you will find them here and be rewarded on every purchase.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import HelpEnTop from '@core/static/Help/Help_en-top.vue';

    export default {
        name: 'HelpEn',
        components: {
            BaseIcon,
            HelpEnTop
        },
        computed: {
            ...mapGetters ( 'appTenant', {
                'isPurchaseFlowEventTicketTypeProvider': 'isPurchaseFlowEventTicketTypeProvider',
                'isPurchaseFlowEventTicketTypeDirect': 'isPurchaseFlowEventTicketTypeDirect',
                'isPurchaseFlowEventTicketTypeBoth':'isPurchaseFlowEventTicketTypeBoth',
                'purchaseFlowEventTicketType': 'purchaseFlowEventTicketType'
            } ),
            returnClassBasedOnTransactionType() {
                if ( this.isPurchaseFlowEventTicketTypeProvider ) {
                    return 'gst-help-bottom';
                }
                else if ( this.isPurchaseFlowEventTicketTypeBoth ) {
                    return 'small-padding';
                }
                else
                    return 'big-padding';
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";
$tmBlue:#026CDF;

.gst-help {
    padding-bottom: 0;

    .gst-help-bottom {
        padding: theme-spacing( 12, 3 );
        margin: theme-spacing( 12, 0 ) auto;
        background-color: #F0F2F3;

        .gst-help-bottom__section {
            width: 44%;
            margin-right: theme-spacing( 6 );

            .gst-help-bottom__section__icon {
                margin-bottom: theme-spacing( 3 );

                svg {
                    width: auto;
                    max-height: 40px;
                }
            }

            .gst-help-bottom__section__text {
                overflow-wrap: break-word;

                h5 {
                    margin-bottom: theme-spacing( 2 );
                    font-weight: 500;
                }

                p {
                    font-weight: 400;
                }
            }
        }
    }

    .gst-svg-color-1 {
        fill: $tmBlue;
    }

    .gst-svg-color-2 {
        fill: theme-color( 'white' );
    }

    .gst-svg-color-3 {
        fill: theme-color( 'primary' );
    }

    .gst-svg-color-4 {
        fill: theme-color( 'secondary' );
    }

    .gst-svg-color-5 {
        fill: theme-color( 'primary-darken-1' );
    }
}

.small-padding {
    padding-bottom: 4% !important;
}

.big-padding {
    padding-bottom: 10% !important;
}

@include mobile-only {
    .gst-help {
        width: 100%;

        .gst-help-bottom {
            width: 100%;
            padding: theme-spacing( 0, 4 );
            background-color: #F0F2F3;
            margin-left: 0;

            .gst-help-bottom__section {
                width: 100%;
                margin-right: 0;

                .gst-help-bottom__section__icon {
                    svg {
                        max-height: 60px;
                    }
                }

                .gst-help-bottom__section__text {
                    overflow-wrap: break-word;

                    h5 {
                        margin-bottom: theme-spacing( 2 );
                        font-weight: 500;
                    }

                    p {
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
</style>
